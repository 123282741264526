.media {
  border-bottom: 1px solid rgba(164, 158, 147, 0.22);
  padding-bottom: 30px;
  margin-top: 30px;

  .avatar {
    margin: 0 auto;
    width: 64px;
    height: 64px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 15px;
    border: 3px solid $white-color;

    img {
      width: 100%;
    }
  }
  .media-heading {
    color: $default-states-color;
    display: inline-block;
    font-weight: $font-weight-bold;
    margin-bottom: 10px;
    margin-top: 5px;
  }
  .btn-simple {
    padding: 0px 5px;
  }
  .media {
    margin-top: 30px;
    margin-left: -25px;
  }
  .media-footer {
    margin-top: 20px;
    .btn-link {
      padding: 7px;
    }
  }
  .media:last-child {
    border: 0;
  }
}

.media-post {
  color: #555;
  border: 0;
  .media-heading {
    display: block;
    text-align: center;
  }
  .author {
    width: 15%;
  }
  .media-body {
    width: 85%;
    float: left;
    display: inline-block;
  }
  textarea {
    margin: $margin-bottom;
    font-size: $font-paragraph;
  }
  .form {
    width: 100%;
  }
}

.media-area {
  .media:last-child {
    border: 0;
  }
  .pagination-area {
    padding: 10px 0;
    text-align: center;
  }
}
.media-area-small {
  p {
    font-size: 14px;
  }
  .btn-simple {
    font-size: 14px;
  }
  .avatar {
    width: 58px;
    height: 58px;
  }
}
