@import './paper-kit/variables';

.node-group {
  cursor: pointer;
  .rect {
    fill: #fff;
    stroke: steelblue;
    stroke-width: 3px;
    width: 16px;
    height: 16px;
    rx: 8px;
    ry: 8px;
  }
  .label {
    font: 16px 'Montserrat', 'Helvetica', Arial, sans-serif;
  }

  &.node--root {
    cursor: default;
  }

  &.node--root-node {
    .label {
      font-size: 24px;
      fill: #9a9a9a;
    }
  }
  &.node--milestone {
    .rect {
      fill: #5e5e5e;
    }
  }

  &.node--beginner {
    .rect {
      stroke: forestgreen;
    }
  }
  &.node--intermediate {
    .rect {
      stroke: dodgerblue;
    }
  }
  &.node--advanced {
    .rect {
      stroke: darkorange;
    }
  }
  &.node--elite {
    stroke: darkred;
  }
}

.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 1px;
}

.d3-component {
  //background: #eee;
}

.loading-mask-wrapper {
  position: relative;
  .loading-mask {
    position: absolute;
    z-index: 1000;
    background: rgba(100, 100, 100, 0.5);
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .loading-mask-spinner {
    position: absolute;
    top: calc(50% - #{80px / 2});
    left: calc(50% - #{80px / 2});
  }
}

.skill-info-section {
  .skill-info-link.nav-link {
    .link-inner {
      padding: 5px 10px;
    }
    &:hover {
      color: $success-states-color;
    }
    &.active {
      .link-inner {
        background-color: $success-color;
      }
      color: #fff;
      &:before,
      &:after {
        content: none;
      }
      &:hover {
        .link-inner {
          background-color: $success-states-color;
        }
        color: #fff;
        &:before,
        &:after {
          content: none;
        }
      }
    }
  }
}

.skill-tree-modal {
  .close {
    color: $danger-color;
  }
  .settings {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: $info-color;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
    background-color: transparent;
    border: none;
    &:focus {
      outline: none;
    }
    &:hover {
      color: darken($info-color, 30%);
    }
  }
}

.react-tagsinput .react-tagsinput-tag {
  &.disabled:hover {
    padding: 4px 8px 3px 0.8em;
    cursor: default;
  }
}
