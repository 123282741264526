.gallery-item {
  margin-bottom: 40px;

  .small-image {
    height: 175px;
  }
  .gallery-caption {
    margin-top: 15px;
    text-align: center;
  }
}
